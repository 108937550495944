<template>
    <v-row :class="`petu-blue-bg ${isImageSet ? 'mt-3' : ''} rounded-t-xl`">
        <v-col cols="12" class="pt-5" v-show="isImageSet"><v-img class="rounded-xl" :src="image"></v-img></v-col>
        <v-col cols="12" class="bg-white rounded-t-xl pt-4 px-0">
            <v-card flat>
                <div class="sticky bg-white">
                    <v-card-title class="text-h5 font-weight-bold" v-text="product.name">}</v-card-title>
                    <v-card-subtitle v-show="product.trainingType != 'NA'">{{ product.trainingType }}</v-card-subtitle>
                    <v-card-text >
                        <v-form ref="productForm">
                            <v-row no-gutters>
                                <v-col cols="4" class="pl-0 d-flex align-end">
                                    <v-text-field v-model="qty" placeholder="Qty" @keydown="intOnly" dense solo flat class="rounded-xl centered-input" :rules="qtyRules" hide-details @click:prepend-inner="qty - 1 >= 0 ? qty--: null" @click:append="qty++" background-color="grey lighten-3" prepend-inner-icon="mdi-minus" append-icon="mdi-plus">></v-text-field>
                                </v-col>
                                <v-col class="pr-0 text-right black--text d-flex justify-end align-center">
                                    <div class="mb-0 headline font-weight-bold" style="color:#ee3a8c">{{ price }}</div>
                                </v-col>
                            </v-row>
                            <v-btn block large depressed class="font-weight-black no-uppercase rounded-lg white--text mt-2" color="secondary" @click="addToCart" :loading="loading">Añadir al carrito</v-btn>
                        </v-form>
                    </v-card-text>
                </div>
                <v-card-text>

                    <div class="mt-5" v-html="product.description"></div>
                    
                    <div v-if="product.trainingType != 'NA'">
                        <h4 class="title">Reglas</h4>
                        <ul>
                            <li>Ser puntual a la hora de su sección de entrenamiento. (15 minutos de gracia, de llegar tarde tendrá que esperar la próxima sección)</li>
                            <li>Debe traer spray de alcohol desinfectante y mascarilla.</li>
                            <li>Traer su mat de ejercicio.</li>
                            <li>Petus ONLINE tienen que enviar video de 1 minuto mínimo al petuphone para evidenciar que haya hecho rutina diaria.</li>
                            <li>Reservaciones de entrenamientos online y presencial se realizan a través de la página de PetuPower.fitness.</li>
                            <li>Créditos de entrenamientos sólo se realizan por cancelaciones realizadas por la entrenadora Kenialyz o situaciones de emergencias con evidencia.</li>
                            <li>Reglas aplican a todas, NO hay excepción de persona.</li>
                        </ul>
                    </div>

                    <div class="mt-5" v-if="product.trainingType != 'NA'">
                        <h4 class="title">Strikes</h4>
                        <ul>
                            <li>Petu online deben enviar video de al menos 1 minuto para evidenciar la rutina. De no enviar video, contará como un strike.</li>
                            <li>Petu presencial que se ausente a entrenamiento 3 veces tendrá un strike.</li>
                            <li>Petu presencial debe firmar en la asistencia diaria que tendrá Kenia para así saber que fue a entrenar. Es su responsabilidad firmar.</li>
                            <li>Los strikes se darán semanales.</li>
                            <li>Un strike será eliminado si tienes asistencia perfecta por dos semanas corridas.</li>
                            <li>Día para reponer cualquier rutina de la semana es Sábado.</li>
                            <li>Esto aplica a todas. NO hay excepción de persona.</li>
                        </ul>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import { PetuProduct } from '@/classes/product.js';
import { _st } from '@/softech';
import { mapActions } from 'vuex';

export default {
	name		: 'Product',
    props: { 
        productId   : { type: String, default: '' },
        slug        : { type: String, default: '' }
    },
    data: () => ({
        product     : {},
        image       : '',
        qty         : 1,
        qtyRules    : [
            v => !!v || 'requerido',
            v => !!_st.isInteger(v) || 'inválido',
            v => v >= 1  || 'inválido'
        ],

        loading     : false,
    }),
    async mounted() {
        if( _st.isEmpty( this.productId ) && _st.isEmpty( this.slug ) ) return; // go to page not found 404
        
        let product = new PetuProduct();

        if( !_st.isEmpty( this.productId ) ) 
            await product.load( this.productId );
        
        else await product.loadBySlug( this.slug );

        if( _st.isEmpty( product.data ) ) {
            this.$router.push('/404');
            return;
        }

        this.product = product.data;
        this.image = await product.getProductImage();
    },
    methods: {
        ...mapActions({
            addToCartAction : 'cartInfo/addToCart',
        }),
        async addToCart() {
            if( !this.$refs.productForm.validate() ) return;

            try {
                this.loading = true;
                await this.addToCartAction({
                    productId: this.product.id,
                    qty: this.qty
                });

                this.$root.$emit('show-cart');

                this.loading = false;
            } catch(err) {
                console.log(error);
                this.loading = false;
            }
        },
        intOnly(e) {
            const key       = e.keyCode;
            const ctrlFlag  = e.ctrlKey || e.metaKey;
            
            if ([46, 8, 9, 27, 13].includes(key) ||    // allow: backspace, delete, tab, escape and enter
                (key == 65 && ctrlFlag) ||                  // allow: ctrl/cmd+A
                (key == 67 && ctrlFlag) ||                  // allow: ctrl/cmd+C
                (key == 88 && ctrlFlag) ||                  // allow: ctrl/cmd+X
                (key >= 35 && key <= 39)) {                 // allow: home, end, left, right
                return;                                     // do nothing
            }
      
            /** if it's a number, stop the keypress */
            if ((e.shiftKey || (key < 48 || key > 57)) && (key < 96 || key > 105)) {
                e.preventDefault();
            }
        },
    },
    computed: {
        price() {
            return this.product.price ? _st.toMoney(this.product.price) : ''
        },
        isImageSet() {
            return !_st.isNUE( this.image );
        }
    },
}
</script>

<style scoped>
.sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
}
.centered-input >>> input {
    text-align: center
}
</style>